@import "variables";
@import "normalize";
@import "../fonts/fonts";
@import "./common/styles";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}
