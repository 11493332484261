.header {
  display: flex;
  height: 70px;
  align-items: center;
  justify-content: space-between;
  padding: 0 40px;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  background-color: #ffffff;
  &__user {
    display: flex;
    align-items: center;
    &_name {
      color: $text-color;
      font-weight: bold;
    }
    &_logo {
      margin-left: 15px;
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #5553ce;
      color: #ffffff;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    &_logout {
      margin-left: 15px;
      span {
        font-size: 22px;
      }
    }
  }
  &__left,
  &__right {
    display: flex;
    align-items: center;
  }
  &__user {
    margin-left: 40px;
  }
  &__clients {
    color: #b2bed9;
    font-weight: bold;
    display: flex;
    align-items: center;
    &_balance {
      margin-right: 15px;
      color: #5553ce;
    }
  }
  &__clients_list,
  &__entrepreneurs {
    width: 200px;
    .select__clear-indicator {
      display: none;
    }
  }
  &__order-number {
    color: $text-color;
    font-weight: 500;
    text-align: center;
  }
  &__entrepreneurs {
    margin-left: 30px;
  }
}
