.table__filter {
  margin: 15px 0;
  display: flex;
  align-items: center;
  .field {
    width: 25%;
    text-align: center;
  }
  input {
    background: #ffffff;
    border: 2px solid #dde2ee;
    box-shadow: 0px 9px 10px rgba(199, 213, 232, 0.17);
    border-radius: 30px;
    width: 200px;
    height: 40px;
  }
  .range-picker {
    text-align: center;
    .ant-picker {
      width: 189px;
      background: #ffffff;
      border: 2px solid #dde2ee !important;
      box-sizing: border-box;
      box-shadow: 0px 9px 10px rgba(199, 213, 232, 0.17);
      border-radius: 30px;
      padding: 0 5px 0 15px;
      .ant-picker-range-separator,
      .ant-picker-active-bar {
        display: none;
      }
      .ant-picker-range-separator {
        right: 30px;
      }
      .ant-picker-clear {
        right: 0px !important;
        width: 25px;
        padding-right: 5px;
        margin-right: 5px !important;
      }
    }
    .ant-picker-input {
      width: 44%;
      input {
        height: 36px;
        border: none;
        box-shadow: none;
        font-size: 12px;
      }
    }
  }
  .sort {
    width: 10%;
    &__content {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      div {
        width: 23px;
        height: 30px;
        cursor: pointer;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0 auto;
        cursor: pointer;
        img {
          width: 100%;
        }
      }
    }
  }
}

.ant-picker {
  width: 200px;
  background: #ffffff;
  border: 2px solid #dde2ee !important;
  box-sizing: border-box;
  box-shadow: 0px 9px 10px rgba(199, 213, 232, 0.17);
  border-radius: 30px;
  padding: 0 5px 0 15px;
  .ant-picker-range-separator,
  .ant-picker-active-bar {
    display: none;
  }
  .ant-picker-range-separator {
    right: 30px;
  }
  .ant-picker-clear {
    right: 5px !important;
    width: 20px;
    height: 18px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &-suffix {
    margin-right: 0 !important;
  }
  &-clear {
    margin-right: 8px !important;
  }
}
.ant-picker-input {
  width: 44%;
  input {
    height: 36px !important;
    border: none !important;
    box-shadow: none !important;
    font-size: 12px !important;
    padding: 0 !important;
  }
}
