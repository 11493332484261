.create-order {
  background: #f2f6fb;
  height: calc(100vh - 70px);
  .table__filter {
    display: none !important;
  }
  .page-title {
    display: none;
  }
  .table {
    font-size: 15px;
    max-height: 290px;
    overflow-y: auto;
    min-height: 290px;
    // .rt-thead {
    //   position: fixed;
    //   left: 0;
    //   z-index: 1;
    //   width: 100%;
    // }
    .rt-tr-group {
      flex: none;
      .select__clear-indicator {
        display: none;
      }
    }
    .rt-tbody {
      // margin-top: 40px;
      background-color: #ffffff;
    }
    .select {
      border: none;
      box-shadow: none;
      &__indicator-separator {
        display: none;
      }
    }
    .rt-tbody {
      .rt-tr-group {
        background-color: #ffffff;
      }
    }
    .ant-input-number .ant-input-number-handler-wrap {
      right: -5px;
    }
  }
  &__formatter {
    display: none;
  }
  .main-content__form {
    padding: 0 20px;
    .order-form__item {
      display: flex;
      flex-wrap: wrap;
      position: relative;
      > span {
        width: 12%;
        margin-right: 2.4%;
        font-weight: 500;
        font-size: 14px;
        color: #717599;
        label {
          text-align: center;
        }
        .ant-input-number {
          width: 100%;
          border: none;
        }
        &.employee-select {
          width: 15%;
        }
        &.service-select,
        &.material-select,
        &.payment-methods-select {
          width: 27.7%;

          label {
            text-align: left;
          }
          .select {
            width: 100%;
            .css-1pcexqc-container {
              width: auto;
            }
          }
        }
        input {
          box-shadow: 0px 10px 10px rgba(85, 83, 206, 0.03);
        }
        &.transfer-number {
          width: 26.5%;
        }
      }
      .ant-input-number-handler-wrap {
        opacity: 1;
        right: 10px;
        height: 80%;
        top: 5px;
      }
    }
    .payment-form__item {
      &-content {
        display: flex;
        flex-wrap: wrap;
      }
    }
    .order-form__submit {
      margin-top: 30px;
      button {
        background: #5553ce;
        box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.25);
        border-radius: 30px;
        border: none;
        font-weight: 500;
        font-size: 17px;
        color: #ffffff;
        padding: 7px 30px;
      }
    }
  }
  .create-order__result {
    margin-top: 90px;
    text-align: center;
    position: absolute;
    right: 30px;
    &-price {
      font-weight: bold;
      font-size: 28px;
      letter-spacing: 0.5px;
      color: $text-color;
      text-transform: uppercase;
      span {
        color: #5553ce;
      }
    }
    .order-form__submit {
      height: 50px;
      button {
        padding: 12px 30px;
      }
    }
  }
}
