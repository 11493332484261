.main-content__form {
  &-title {
    font-weight: 500;
    font-size: 16px;
    color: #5553ce;
    margin-bottom: 15px;
  }
  &-error {
    font-weight: 500;
    font-size: 16px;
    color: red;
    margin-bottom: 15px;
  }
  label {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: $text-color;
    display: block;
    margin-top: 10px;
  }
  input.ant-input-disabled {
    &::placeholder {
      color: $text-color;
    }
  }
  .c-form {
    &__item {
      input,
      .ant-select {
        background: #ffffff;
        border: 2px solid #f2f6fb;
        box-sizing: border-box;
        box-shadow: 0px 10px 10px rgba(85, 83, 206, 0.03);
        border-radius: 30px;
        color: #717599;
        font-weight: 500;
        font-size: 14px;
        padding: 5px 20px;
        width: 100%;
        height: 40px;
      }
      .select {
        &__single-value {
          color: #717599;
          font-weight: 700;
          font-size: 14px;
        }
      }
      &-group {
        display: flex;
        span:first-child {
          margin-right: 55px;
        }
        &.comings-group {
          span {
            margin-right: 15px;
          }
        }
      }
      .ant-select {
        outline: none;
        padding: 0;
        overflow: hidden;
        .ant-select-selector {
          border: none;
          padding: 0 20px;
          &:focus {
            border: none;
          }
          outline: none;
        }
      }
    }
    &__submit,
    &__add-item {
      text-align: center;
      button {
        background: #00cba6;
        box-shadow: 0px 10px 15px rgba(0, 208, 196, 0.2);
        border-radius: 30px;
        color: #ffffff;
        border: none;
        outline: none;
        margin-top: 20px;
        padding: 9px 25px;
      }
    }
    &__close-item {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
    &__field--required {
      color: red;
    }
    &__add-item {
      text-align: right;
      button {
        background: #5553ce;
        box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.18);
      }
    }
    &__checkbox {
      .ant-checkbox-checked .ant-checkbox-inner {
        background-color: #5553ce;
        border-color: #5553ce;
      }
    }
  }
  .ant-select-focused.ant-select-single:not(.ant-select-customize-input)
    .ant-select-selector {
    border: none;
    border-right-width: 0 !important;
  }

  .ant-form-explain {
    left: 0;
    position: absolute;
    font-weight: 500;
    color: red;
  }
  .phone-field {
    input::placeholder {
      color: $text-color;
    }
  }
}

input::placeholder {
  color: $text-color;
}

