.table {
  border: none;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
  border-radius: 6px;
  .rt-table {
    overflow: inherit;
  }
  .rt-thead {
    font-size: 13px;
    color: $text-color;
    text-transform: uppercase;
    letter-spacing: 0.6px;
    font-weight: bold;
    height: 40px;
    .rt-tr {
      background: #f2f6fb;
      box-shadow: inset 0 0.9px 3px rgba(201, 216, 236, 0.64),
        inset 0px -1.5px 3px rgba(187, 204, 225, 0.26);
      .rt-th {
        border: none;
        padding: 0;
        > * {
          height: 100%;
        }
        .rt-th-cell {
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100%;
        }
      }
    }
    &.-filters {
      display: none;
    }
    &.-header {
      box-shadow: none;
    }
  }
  .rt-tbody {
    overflow: initial;
    .rt-tr-group {
      overflow: inherit;
      border: none;
      background-color: #ffffff;
      &:nth-child(2n) {
        background: rgba(85, 83, 206, 0.06);
      }
      .rt-td {
        padding: 0;
        min-height: 50px;
        display: flex;
        align-items: center;
        border: none;
        justify-content: center;
        overflow: inherit;
        text-align: center;
        white-space: inherit;
        word-break: break-word;
        > * {
          display: flex;
          align-items: center;
        }
        &.left {
          justify-content: left;
        }
      }
    }
  }

  .rt-td {
    gap: 40px;
  }
  .rt-noData {
    top: 70%;
    padding: 0;
    color: $text-color;
  }
  .select__option {
    padding: 0 5px;
  }

  &__pagination {
    display: flex;
    padding: 20px 0;
    justify-content: center;
    &-prevPageWrapper,
    &-nextPageWrapper {
      display: none;
    }
    &-visiblePagesWrapper {
      button {
        margin: 0 10px;
        border: none;
        background: none;
        font-weight: 500;
        font-size: 18px;
        letter-spacing: 0.6px;
        color: $text-color;
        &.table__pagination-pageButton--active {
          height: 35px;
          width: 35px;
          border-radius: 50%;
          background: rgba(85, 83, 206, 0.1);
        }
      }
    }
  }
}

.sub-table {
  .rt-thead {
    display: none;
  }
  .rt-tbody {
    .rt-tr-group {
      padding-left: 30px;
      &:nth-child(2n) {
        background: rgba(5, 3, 10, 0.16);
      }
      &:nth-child(2n-1) {
        background: #cac5cc;
      }
    }
  }
}

//table formatters
.name-formatter {
  text-align: left;
  padding-left: 70px;
  font-weight: 500;
  font-size: 15px;
  color: #51567c;
  span {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0.2px;
    color: #00cba6;
    margin-right: 35px;
    background: rgba(0, 203, 166, 0.1);
    height: 35px;
    width: 35px;
    border-radius: 50%;
    line-height: 35px;
    text-align: center;
  }
  a {
    width: 200px;
  }
  &.blocked {
    color: #dde2ee;
    span {
      color: #b2bed9;
      background: #dde2ee;
    }
  }
}

.balance-formatter {
  color: $text-color;
  font-weight: 600;
  font-size: 16px;
  margin: 0 auto;
}

.replenish-formatter,
.collection-action-formatter {
  margin: 0 auto;
  button {
    background: #5553ce;
    box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.25);
    border-radius: 30px;
    padding: 8px 35px;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.3s;
    height: auto;
    box-shadow: none;
    &:hover {
      opacity: 0.7;
      background: #5553ce;
      color: #ffffff;
    }
  }
}

.link-formatter {
  margin: 0 auto;
  color: #5553ce;
}

.action-formatter,
.edit-formatter {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background: #fdeef0;
  margin: 0 auto;
  cursor: pointer;
  img {
    margin: 0 auto;
  }
}

.edit-formatter {
  background-color: #eeeefd;
  svg {
    margin: 0 auto;
  }
}

.discount-formatter {
  span {
    padding: 5px 25px;
    color: #00cba6;
    font-weight: 500;
    background: rgba(0, 203, 166, 0.1);
    border-radius: 15px;
    text-align: center;
    &.wrong {
      color: #eb5468;
      background: rgba(235, 84, 104, 0.13);
    }
    &.normal {
      background: rgba(0, 203, 166, 0.1);
      color: #00cba6;
    }
  }
}

.orders-replenish-formatter {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  p {
    margin-right: 10px;
  }
  button {
    background: #5553ce;
    box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.25);
    border-radius: 30px;
    padding: 8px 25px;
    font-weight: 500;
    color: #ffffff;
    transition: all 0.3s;
    height: auto;
    box-shadow: none;
    &:hover {
      opacity: 0.7;
      background: #5553ce;
      color: #ffffff;
    }
  }
}

.service-status-formatter,
.order-status-formatter {
  background: rgba(0, 203, 166, 0.1);
  border-radius: 15px;
  font-weight: 500;
  font-size: 14px;
  color: #00cba6;
  padding: 5px 15px;
  &.blocked,
  &.no-paid {
    color: #eb5468;
    background: rgba(235, 84, 104, 0.13);
  }
  &.partial-paid {
    color: #ffffff;
    background: #f2a000;
  }
}
