body {
  font-family: $main-font;
}
.client-main {
  display: flex;
  &__sidebar {
    flex-grow: 1;
    max-width: 400px;
  }
  &__map {
    flex-grow: 1;
    .leaflet-container {
      min-height: 100vh;
      width: 100%;
    }
  }
}
.table__filter .ant-input {
  &::placeholder {
    color: $text-color;
    font-weight: bold;
  }
}

.btn-add {
  position: absolute;
  top: 225px;
  left: 72px;
  width: 27px;
  z-index: 1;
  button {
    background: #5553ce;
    box-shadow: 0px 4px 13px rgba(85, 83, 206, 0.25);
    z-index: 1;
    height: 27px;
    width: 27px;
    border-radius: 50%;
    padding: 0;
    span {
      width: 100%;
      height: 100%;
      color: #ffffff;
    }
    &:hover {
      opacity: 0.7;
      background: #5553ce;
      color: #ffffff;
    }
  }
}

button,
input,
select {
  outline: none;
}

.ant-input-number {
  background: none;
  outline: none;
  border: none;
  font-size: 14px;
  color: #717599;
  overflow: hidden;
  box-shadow: none;
  width: 100%;
  &:focus,
  &-focused {
    border: none;
    outline: none;
  }
  .ant-input-number-handler-wrap {
    opacity: 1;
    border: none;
    right: 10%;
  }
  .ant-input-number-handler {
    border: none;
  }
  .anticon {
    color: #4c5871;
  }
}

.css-1pcexqc-container {
  margin: 0 auto;
  height: 40px;
  border: none;
}

.select {
  height: 40px;
  background: #ffffff;
  border: 2px solid #dde2ee;
  box-shadow: 0px 9px 10px rgba(199, 213, 232, 0.17);
  border-radius: 30px;
  &:focus {
    outline: none;
  }
  &__control,
  .css-bg1rzq-control {
    height: 100%;
    width: 100%;
    display: flex;
    min-height: 36px !important;
    border-radius: 30px;
    border: none;
    outline: none !important;
    &.css-1pahdxg-control,
    &.css-yk16xz-control {
      border: none;
      outline: none !important;
      box-shadow: none;
      overflow: hidden;
      border-radius: inherit;
      background-color: initial;
    }
    .select__indicator-separator {
      display: none;
    }
    &:hover {
      border: none;
      border-color: none !important;
    }
  }
  .css-g1d714-ValueContainer,
  &__value-container {
    height: 100%;
    padding: 0px 8px;
  }
  &__control--menu-is-open {
    .select__placeholder {
      margin-top: 0;
      white-space: normal;
    }
  }
  .css-1hwfws3 {
    width: 200px;
    padding: 0 10px;
  }
  &__placeholder,
  &__single-value,
  .css-1uccc91-singleValue {
    color: $text-color !important;
    font-weight: bold;
    position: static !important;
    height: 100%;
    display: flex;
    align-items: center;
    transform: none !important;
    span {
      position: absolute;
    }
  }
  &__option {
    padding: 0 5px !important;
    color: $text-color !important;
    font-weight: bold;
    background: red;
  }
  .css-b8ldur-Input {
    padding: 0;
    height: 100%;
  }
  &__menu-list {
    padding: 0;
    border-radius: 0;
    border: none;
    cursor: pointer;
    margin: 10px 0;
  }
  &__menu {
    border: none;
    margin: 5px 0;
    padding: 0;
    background-color: #ffffff;
    letter-spacing: -0.01px;
    font-size: 13px;
    border-radius: 0;
    z-index: 99 !important;
    text-align: center;
    cursor: pointer;
    width: 100% !important;
    .select-value {
      margin: 0 !important;
    }
    > div {
      > div {
        div {
          //margin: 5px 0;
        }
        // > div {
        //   div {
        //     pointer-events: all !important;
        //     position: static !important;
        //     height: auto !important;
        //     left: 0 !important;
        //     top: 0 !important;
        //     line-height: initial !important;
        //     margin: 5px 0;
        //     padding: 0 2px !important;
        //     font-size: 14px;
        //   }
        // }
      }
    }
  }
  .css-26l3qy-menu {
    margin-top: 2px;
  }
  .css-1g6gooi {
    height: 100%;
    padding: 0;
    margin: 0;
  }
  &__input {
    height: 100%;
    input {
      height: 100%;
    }
  }
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  padding: 0;
  margin: 0;
}

.btn-print {
  button {
    background: #00cba6;
    box-shadow: 0px 10px 15px rgba(0, 208, 196, 0.2);
    border-radius: 30px;
    color: #ffffff;
    border: none;
    outline: none;
    margin: 20px auto 0 auto;
    padding: 7px 25px;
  }
}

.btn-addNotes {
  margin-right: 10px;
  button {
    background: #5755ce;
    color: #ffffff;
    border: none;
    outline: none;
  }
}

.main-wrapper {
  height: 100vh;
}

.main-container {
  display: flex;
  max-height: calc(100vh - 70px);
  justify-content: flex-end;
  overflow: auto;
  .main__content {
    position: fixed;
    top: 350px;
    left: 0;
  }
  > * {
    width: 50%;
    text-align: center;
  }
  .sidebar {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    padding-top: 30px;
    .sidebar__btn {
      display: block;
      width: 300px;
      margin: 0 auto;
      color: #5553ce;
      background: #ffffff;
      border: 1px solid #dde2ee;
      box-sizing: border-box;
      box-shadow: 0px 15px 36px rgba(210, 209, 235, 0.28);
      border-radius: 30px;
      margin-bottom: 20px;
      height: 60px;
      line-height: 60px;
      font-weight: 500;
      font-size: 22px;
    }
  }
  .main__content {
    &_title {
      font-weight: 500;
      font-size: 48px;
      line-height: 56px;
      color: #b2bed9;
    }
    &_btn {
      background: #5553ce;
      box-shadow: 0px 15px 36px rgba(85, 83, 206, 0.28);
      border-radius: 30px;
      outline: none;
      border: none;
      height: 60px;
      display: inline-block;
      padding: 0 70px;
      color: #ffffff;
      font-weight: 600;
      font-size: 22px;
      line-height: 60px;
      a {
        padding: 10px 70px;
        display: block;
        color: #ffffff;
        font-weight: 600;
        font-size: 22px;
      }
    }
  }
}
.summary {
  display: flex;
  justify-content: flex-end;
  background-color: #aaaaaa1e;
  .right {
    display: flex;
    justify-content: flex-end;
    width: 60vw;
    margin: 0 auto;
    h4 {
      padding: 20px 10px;
      font-weight: bold;
      font-size: 15px;
    }
  }
  @media only screen and (min-width: 1824px) {
    .right {
      width: 58vw;
      h4 {
        padding: 25px 20px;
      }
    }
  }
}
.page-title {
  font-size: 25px;
  font-weight: bold;
  span {
    font-size: 20px;
    color: gray;
  }
}
.createNoteContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  .createNoteInput {
    background: #ffffff;
    border: 2px solid #dde2ee !important;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 0 5px 0 15px;
    height: 40px;
    width: 70%;
  }
  .createNoteButton {
    background: #00cba6;
    border-radius: 30px;
    color: #ffffff;
    border: none;
    outline: none;
    padding: 9px 25px;
    cursor: pointer;
  }
}
.noteContainer {
  .noteDefaultContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap-reverse;
    h2 {
      padding: 5px;
      font-size: 18px;
      width: 450px;
    }
  }
  .noteEditContainer {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }


  .noteEditInput {
    background: #ffffff;
    border: 2px solid #dde2ee !important;
    box-sizing: border-box;
    border-radius: 30px;
    padding: 10px 5px 10px 15px;
    //height: 120px;
    width: 70%;
  }


  .noteEditInput div {
    display: block !important;
  }
  .noteEditButton {
    font-weight: 500;
    font-size: 16px;
    color: #5553ce;
    cursor: pointer;
  }
  .noteSubmitButton {
    display: flex;
    justify-content: center;
    button {
      background: #00cba6;
      border-radius: 30px;
      color: #ffffff;
      border: none;
      outline: none;
      margin-top: 20px;
      padding: 9px 25px;
    }
  }
}
.printMaterialsButton {
  display: flex;
  justify-content: flex-end;
  button {
    background: #00cba6;
    border-radius: 30px;
    color: #fff;
    height: 35px !important;
    border: none;
    outline: none;
    padding: 7px 25px !important;
    margin: 20px auto 0;
  }
}

.notes {
  width: 50%;
  margin-top: 15px;
  label {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: black;
    display: block;
  }
  .textareaInput {
    resize: none;
  }
}
.css-1thkkgx-indicatorContainer {
  margin-top: -3px;
  padding: 15px !important;
}
.css-16pqwjk-indicatorContainer {
  margin-top: -3px;
  padding: 15px !important;
}


