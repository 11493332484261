.c-form__checkbox {
  .c-form__field {
    display: flex;
    flex-direction: column-reverse;
    // padding-left: 100px;
  }

  span {
    font-weight: bold;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #b2bed9;
  }

  .ant-checkbox {
    margin-top: 11px;
  }
}

.ant-checkbox+span {
  margin-top:-26px;
}
