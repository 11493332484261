.ant-modal {
  &-content {
    width: 100%;
    border-radius: 20px;
    height: 100%;
  }
  &-close {
    top: -20px !important;
    right: -30px !important;
    .anticon {
      width: 40px;
      height: 40px;
      background: #5553ce;
      box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.2);
      border-radius: 50%;
      display: flex;
      align-items: center;

      svg {
        margin: 0 auto;
        line-height: 40px;
        color: #ffffff;
      }
    }
  }
}

.create-order-modal {
  &__content {
    text-align: center;
    h3 {
      font-weight: 500;
      font-size: 22px;
      color: #5553ce;
      margin-bottom: 20px;
    }
    p {
      margin: 20px 0;
      font-size: 16px;
      color: #b2bed9;
    }
    a {
      background: #00cba6;
      box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.1),
        0px -4px 15px rgba(85, 83, 206, 0.04);
      border-radius: 30px;
      padding: 10px 35px;
      span {
        font-weight: 600;
        color: #ffffff;
      }
    }
  }
}

.order-payment-details {
  &__btn-print {
    text-align: center;
    button {
      background: #00cba6;
      box-shadow: 0px 10px 15px rgba(0, 208, 196, 0.2);
      border-radius: 30px;
      color: #ffffff;
      height: 35px !important;
      border: none;
      outline: none;
      margin: 20px auto 0 auto;
      padding: 7px 25px !important;
    }
  }
  .table {
    box-shadow: none;
    .rt-thead {
      font-weight: bold;
      font-size: 16px;
      color: #b2bed9;
      text-transform: capitalize;
      .rt-tr {
        background-color: #ffffff;
        box-shadow: none;
      }
    }
    .rt-tbody {
      background: #f2f6fb;
      border: 1px solid #e6f0fd;
      box-sizing: border-box;
      border-radius: 20px;
      overflow: hidden;
      .rt-tr-group {
        background: #f2f6fb;
      }
    }
  }
}

.order-history-details {
  .ReactTable {
    .select__clear-indicator {
      display: none;
    }
  }
}

.order-payment-details,
.order-history-details {
  &__title {
    font-weight: 500;
    font-size: 20px;
    color: #5553ce;
  }
}

.cash-register-history__modal {
  display: flex;
  align-items: center;
  height: 400px;
  .ant-modal-body {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-content {
    text-align: center;
    h3 {
      color: #717599;
      font-size: 26px;
      margin-bottom: 20px;
      span {
        color: #5553ce;
        text-transform: uppercase;
      }
    }
    .field {
      display: flex;
      align-items: center;
      label {
        color: #b2bed9;
        margin-right: 20px;
      }
    }
  }
}

.collection-modal {
  .select {
    width: 100%;
    .c-form__field {
      width: 100%;
    }
  }
}

.order-history-details__delete-order {
  .ant-modal-body {
    .c-form__item {
      margin-bottom: 20px;
      > span {
        display: flex;
        align-items: center;
        label {
          margin-right: 10px;
          width: 50%;
          text-align: center;
        }
        input {
          width: 50%;
        }
      }
      &-title {
        font-weight: 700;
        margin-bottom: 10px;
        text-align: center;
      }
      &-group {
        span {
          label {
            margin-right: 10px;
            span {
              margin-right: 0;
            }
          }
        }
      }
    }
  }
}
