/* This stylesheet generated by Transfonter (https://transfonter.org) on August 2, 2017 10:14 PM */

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-HeavyItalic.eot');
// 	src: local('SF UI Text Heavy Italic'), local('SFUIText-HeavyItalic'),
// 		url('SFUIText-HeavyItalic.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-HeavyItalic.woff') format('woff'),
// 		url('SFUIText-HeavyItalic.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-LightItalic.eot');
// 	src: local('SF UI Text Light Italic'), local('SFUIText-LightItalic'),
// 		url('SFUIText-LightItalic.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-LightItalic.woff') format('woff'),
// 		url('SFUIText-LightItalic.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-MediumItalic.eot');
// 	src: local('SF UI Text Medium Italic'), local('SFUIText-MediumItalic'),
// 		url('SFUIText-MediumItalic.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-MediumItalic.woff') format('woff'),
// 		url('SFUIText-MediumItalic.ttf') format('truetype');
// 	font-weight: 500;
// 	font-style: italic;
// }

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Medium.eot');
	src: local('SF UI Display Medium'), local('SFUIDisplay-Medium'),
		url('font/SFUIDisplay-Medium.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Medium.woff') format('woff'),
		url('font/SFUIDisplay-Medium.ttf') format('truetype');
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Regular.eot');
	src: local('SF UI Display Regular'), local('SFUIDisplay-Regular'),
		url('font/SFUIDisplay-Regular.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Regular.woff') format('woff'),
		url('font/SFUIDisplay-Regular.ttf') format('truetype');
	font-weight: normal;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Light.eot');
	src: local('SF UI Display Light'), local('SFUIDisplay-Light'),
		url('font/SFUIDisplay-Light.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Light.woff') format('woff'),
		url('font/SFUIDisplay-Light.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Thin.eot');
	src: local('SF UI Display Thin'), local('SFUIDisplay-Thin'),
		url('font/SFUIDisplay-Thin.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Thin.woff') format('woff'),
		url('font/SFUIDisplay-Thin.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Ultralight.eot');
	src: local('SF UI Display Ultralight'), local('SFUIDisplay-Ultralight'),
		url('font/SFUIDisplay-Ultralight.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Ultralight.woff') format('woff'),
		url('font/SFUIDisplay-Ultralight.ttf') format('truetype');
	font-weight: 100;
	font-style: normal;
}

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Semibold.eot');
// 	src: local('SF UI Text Semibold'), local('SFUIText-Semibold'),
// 		url('SFUIText-Semibold.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Semibold.woff') format('woff'),
// 		url('SFUIText-Semibold.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: normal;
// }

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Semibold.eot');
	src: local('SF UI Display Semibold'), local('SFUIDisplay-Semibold'),
		url('font/SFUIDisplay-Semibold.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Semibold.woff') format('woff'),
		url('font/SFUIDisplay-Semibold.ttf') format('truetype');
	font-weight: 600;
	font-style: normal;
}

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Regular.eot');
// 	src: local('SF UI Text Regular'), local('SFUIText-Regular'),
// 		url('SFUIText-Regular.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Regular.woff') format('woff'),
// 		url('SFUIText-Regular.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: normal;
// }

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Black.eot');
	src: local('SF UI Display Black'), local('SFUIDisplay-Black'),
		url('font/SFUIDisplay-Black.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Black.woff') format('woff'),
		url('font/SFUIDisplay-Black.ttf') format('truetype');
	font-weight: 300;
	font-style: normal;
}

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-BoldItalic.eot');
// 	src: local('SF UI Text Bold Italic'), local('SFUIText-BoldItalic'),
// 		url('SFUIText-BoldItalic.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-BoldItalic.woff') format('woff'),
// 		url('SFUIText-BoldItalic.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Bold.eot');
// 	src: local('SF UI Text Bold'), local('SFUIText-Bold'),
// 		url('SFUIText-Bold.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Bold.woff') format('woff'),
// 		url('SFUIText-Bold.ttf') format('truetype');
// 	font-weight: bold;
// 	font-style: normal;
// }

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Heavy.eot');
	src: local('SF UI Display Heavy'), local('SFUIDisplay-Heavy'),
		url('font/SFUIDisplay-Heavy.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Heavy.woff') format('woff'),
		url('font/SFUIDisplay-Heavy.ttf') format('truetype');
	font-weight: 900;
	font-style: normal;
}

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Light.eot');
// 	src: local('SF UI Text Light'), local('SFUIText-Light'),
// 		url('SFUIText-Light.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Light.woff') format('woff'),
// 		url('SFUIText-Light.ttf') format('truetype');
// 	font-weight: 300;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Heavy.eot');
// 	src: local('SF UI Text Heavy'), local('SFUIText-Heavy'),
// 		url('SFUIText-Heavy.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Heavy.woff') format('woff'),
// 		url('SFUIText-Heavy.ttf') format('truetype');
// 	font-weight: 900;
// 	font-style: normal;
// }

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Medium.eot');
// 	src: local('SF UI Text Medium'), local('SFUIText-Medium'),
// 		url('SFUIText-Medium.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Medium.woff') format('woff'),
// 		url('SFUIText-Medium.ttf') format('truetype');
// 	font-weight: 500;
// 	font-style: normal;
// }

@font-face {
	font-family: 'SF UI Display';
	src: url('font/SFUIDisplay-Bold.eot');
	src: local('SF UI Display Bold'), local('SFUIDisplay-Bold'),
		url('font/SFUIDisplay-Bold.eot?#iefix') format('embedded-opentype'),
		url('font/SFUIDisplay-Bold.woff') format('woff'),
		url('font/SFUIDisplay-Bold.ttf') format('truetype');
	font-weight: bold;
	font-style: normal;
}

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-Italic.eot');
// 	src: local('SF UI Text Italic'), local('SFUIText-Italic'),
// 		url('SFUIText-Italic.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-Italic.woff') format('woff'),
// 		url('SFUIText-Italic.ttf') format('truetype');
// 	font-weight: normal;
// 	font-style: italic;
// }

// @font-face {
// 	font-family: 'SF UI Text';
// 	src: url('SFUIText-SemiboldItalic.eot');
// 	src: local('SF UI Text Semibold Italic'), local('SFUIText-SemiboldItalic'),
// 		url('SFUIText-SemiboldItalic.eot?#iefix') format('embedded-opentype'),
// 		url('SFUIText-SemiboldItalic.woff') format('woff'),
// 		url('SFUIText-SemiboldItalic.ttf') format('truetype');
// 	font-weight: 600;
// 	font-style: italic;
// }
