.order-history {
  .table__filter {
    span {
      width: 10%;
      &.range-picker {
        width: 15%;
      }
      &.clients-select {
        width: 20%;
        .select {
          width: 70%;
        }
      }
      .select,
      input {
        width: 150px;
      }
    }
  }
}

.order-history-details {
  .table__filter {
    display: none;
  }
  &__btn-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px 10px;
    div {
      display: flex;
    }
    > * {
      margin-left: 10px;
    }
    button {
      border-radius: 30px;
      padding: 7px 25px;
      height: auto;
      margin-left: 10px;
    }
    .btn-print {
      button {
        margin: 0;
      }
    }
  }
}

.order-history-details-single {
  text-align: center;
  .select {
    width: 200px;
  }
  &__save-btn {
    button {
      background: #00cba6;
      box-shadow: 0px 10px 15px rgba(0, 208, 196, 0.2);
      border-radius: 30px;
      color: #ffffff;
      border: none;
      outline: none;
      margin-top: 20px;
      padding: 7px 25px;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.client-orders-details {
  .table__filter {
    span {
      &.sort {
        width: 10%;
      }
      &.filter__search-field {
        input {
          width: 150px;
          height: 40px;
        }
      }
      &.range-picker {
        width: 13%;
      }
      .select {
        width: 183px;
      }
    }
  }
  .order-payment-details__btn-print {
    text-align: left;
    margin-right: 30px;
  }
  &__description {
    padding-left: 15px;
    font-weight: 600;
    p {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
      color: #5553ce;
      span {
        font-weight: bold;
        color: #717599;
      }
    }
  }
}

.clientsHeader{
  display: flex;
  justify-content: space-between;
}

.clients {
  .select {
    width: 250px !important;
  }
  .btn-add {
   position: static;
    margin-left: 20px;
    margin-right: 13px;
  }
  .clients-select {
    width: 10%;
  }
  .client-status {
    width: 10%;
  }
  .table__filter {
    span {
      &.filter__search-field {
        width: 10%;
        input {
          width: 150px;
        }
      }
      &.clients-select {
        width: 14%;
      }
      &.client-status {
        width: 15%;
      }
    }
  }
}

.collection {
  .table__filter {
    display: none;
  }
  &-link {
    margin: 15px 0 0 15px;
    a {
      background: #00cba6;
      box-shadow: 0px 10px 15px rgba(0, 208, 196, 0.2);
      border-radius: 30px;
      color: #ffffff;
      border: none;
      outline: none;
      padding: 10px 25px;
      display: inline-block;
      &:hover {
        opacity: 0.7;
      }
    }
  }
}

.collection-history {
  .css-1pcexqc-container {
    width: 200px;
  }
  .table__filter {
    margin-top: 15px;
    span {
      width: 20%;
      &.sort {
        width: 10%;
      }
    }
  }
}

.cash-register-history {
  .table__filter {
    margin: 15px 0;
    span {
      &.filter__search-field {
        width: 8%;
        input {
          width: 100px;
        }
      }
      .select {
        width: 150px;
        &__menu {
          width: 200px !important;
        }
      }
      &.range-picker {
        width: 12%;
        .ant-picker {
          width: 90%;
        }
      }
      &.sort {
        width: 15%;
        .sort__content {
          justify-content: flex-end;
          margin-right: 40px;
          div {
            margin: 0;
          }
        }
      }
      width: 10%;
      &:last-child {
        width: 25%;
        input {
          width: 300px;
        }
      }
    }
  }
  &__paydesk-balance {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px;
    text-align: right;
  }
  &__balance-block {
    margin: 15px 0 0 15px;
    p {
      font-size: 15px;
      color: #717599;
      font-weight: 500;
      span {
        color: #5553ce;
        text-transform: uppercase;
      }
    }
  }
}

.default-btn {
  a,
  button {
    background: #00cba6;
    box-shadow: 0px 10px 15px rgba(0, 208, 196, 0.2);
    border-radius: 30px;
    color: #ffffff;
    height: 42px;
    border: none;
    outline: none;
    padding: 10px 25px;
    display: inline-block;
    transition: all 0.3s;
    &:hover {
      opacity: 0.7;
    }
  }
}

.balances-history {
  .table__filter {
    margin: 15px 0;
    span {
      width: 12%;
      .select {
        width: 90%;
      }
      input {
        width: 150px;
      }
      &.sort {
        display: flex;
        width: 5%;
      }
      &.range-picker,
      &.transfer-number {
        width: 13%;
        .ant-picker {
          width: 80%;
        }
      }
      &.transfer-number {
        width: 10%;
      }
    }
    .clients-select {
      width: 15%;
      .select {
        width: 95%;
      }
    }
  }
  .select {
    &__menu {
      font-size: 12px;
    }
  }
}

.report-by-worker,
.report-by-client,
.report-by-materials,
.consolidated-report {
  .table__filter {
    display: none;
  }
  &__bottom,
  &__top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 15px;
  }
  &__details,
  &__top {
    p {
      font-size: 15px;
      text-transform: uppercase;
      font-weight: 500;
      color: #5553ce;
      span {
        text-transform: initial;
        font-weight: bold;
        color: #717599;
      }
    }
  }
}

.reports-forms {
  .select {
    width: 250px !important;
  }
  padding: 0 30px;
  .main-content__form {
    .c-form__item {
      display: flex;
      margin-bottom: 30px;
      span {
        margin-right: 30px;
      }
      .c-form__submit {
        margin-top: 39px;


        button {
          margin: 0;
        }
      }
      &-range {
        .ant-picker {
          width: 250px;
        }
      }
    }
    .table__filter {
      display: block;
      margin: 0;
      .select {
        margin: 0;
      }
    }
  }
}

.small-select {
  width: 53%;
}

.comings {
  .select {
    width: 200px !important;
  }
  .css-kj6f9i-menu {
    width: 200px !important;
  }

  .table__filter {
    margin: 15px 0 15px 50px;
    .field {
      width: 12.3%;
      input {
        width: 150px;
      }
    }
    .range-picker {
      width: 20%;
    }
  }
  &__btn {
    display: flex;
    justify-content: space-between;
    button {
      background: #5553ce;
      box-shadow: 0px 10px 15px rgba(85, 83, 206, 0.25);
      border-radius: 30px;
      font-weight: 500;
      font-size: 17px;
      color: #ffffff;
      margin: 20px;
      padding: 10px 30px;
      height: auto;
      &:hover {
        background: #5553ce;
        color: #ffffff;
        opacity: 0.7;
      }
    }
  }
}

.materials {
  .select {
    width: 100% !important;
  }
  .table__filter {
    .field {
      width: 15%;
    }
  }
  .btn-add {
   position: static;
    margin-left: 19px;
  }
  .default-btn {
    margin-top: 10px;
  }
}

.services {
  .css-1pcexqc-container {
    width: 200px;
  }
  .btn-add {
    position: static;
    margin-left: 20px;
  }
  .c-form__item {
    .select-content {
      .select {
        width: 10px;
      }
    }
  }

}

.paydesks {
  .btn-add {
   position: static;
    margin-left: 23px;
  }
}

.clearIconPaydesk {
  color: #CCCCCC;
  display: flex;
  position: absolute;
  margin-left: 373px;
  top: 0;
  margin-top: 180px;
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
}
